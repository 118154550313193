<template>
    <div class="scan-page">

        <!-- Barcode picker -->
        <scanner
            @barcodeHasBeenScanned="barcodeWasScanned"
            ref="barcodePicker"
        ></scanner>

        <template v-if="shipmentIdBeingPacked">
            <!-- Details -->
            <div class="scan-details">
                <div class="container">
                    <h4 class="d-flex align-items-center mb-2">Shipment {{ shipmentIdBeingPacked }} <button class="btn btn-danger btn-sm ml-auto" v-if="shipmentIdBeingPacked" @click="$bvModal.show('complete-shipment-modal')">Mark as complete</button></h4>
                    <h5 class="text-subtitle mb-0">{{ repairKitsPacked.length }} of {{ repairKits.length }} packed</h5>
                </div>
            </div>

            <div class="container scan-content">

                <!-- Repair Kit List -->
                <b-tabs content-class="mt-3">
                    <!-- All repair kits -->
                    <b-tab title="All Repair Kits" active>
                        <div class="card">
                            <ul class="checklist my-0" v-if="repairKits.length > 0">
                                <li
                                    v-for="repairKit in repairKits"
                                    :key="repairKit.id"
                                    :class="{'complete': repairKit.state_constant === 'PACKED'}"
                                    class="checklist-item no-hover">
                                    <div>Repair kit: {{ repairKit.day_number }}</div>
                                    <div class="text-sm">{{ repairKit.technician_name }}</div>
                                </li>
                            </ul>
                            <empty-state
                                v-if="repairKits.length === 0"
                                emptyIcon
                                icon="box"
                                inline
                                small
                                title=""
                                subtitle="No repair kits have been packed">
                            </empty-state>
                        </div>
                    </b-tab>

                    <!-- To be packed -->
                    <b-tab title="To Be Packed">
                        <div class="card">
                            <ul class="checklist my-0" v-if="repairKitsPicked.length > 0">
                                <li
                                    v-for="repairKit in repairKitsPicked"
                                    :key="repairKit.id"
                                    :class="{'complete': repairKit.state_constant === 'PACKED'}"
                                    class="checklist-item no-hover">
                                    <div>Repair kit: {{ repairKit.day_number }}</div>
                                    <div class="text-sm">{{ repairKit.technician_name }}</div>
                                </li>
                            </ul>
                            <empty-state
                                v-if="repairKitsPicked.length === 0"
                                emptyIcon
                                icon="box"
                                inline
                                small
                                title=""
                                subtitle="No repair kits to be packed">
                            </empty-state>
                        </div>
                    </b-tab>

                    <!-- Packed -->
                    <b-tab title="Packed">
                        <div class="card">
                            <ul class="checklist my-0" v-if="repairKitsPacked.length > 0">
                                <li
                                    v-for="repairKit in repairKitsPacked"
                                    :key="repairKit.id"
                                    :class="{'complete': repairKit.state_constant === 'PACKED'}"
                                    class="checklist-item no-hover">
                                    <div>Repair kit: {{ repairKit.day_number }}</div>
                                    <div class="text-sm">{{ repairKit.technician_name }}</div>
                                </li>
                            </ul>
                            <empty-state
                                v-if="repairKitsPacked.length === 0"
                                emptyIcon
                                icon="box"
                                inline
                                small
                                title=""
                                subtitle="No repair kits have been packed">
                            </empty-state>
                        </div>
                    </b-tab>
                </b-tabs>

                <!-- Mark return as complete -->
                <shipment-complete-modal></shipment-complete-modal>

            </div>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters([
            'appRegion',
            'repairKits',
            'repairKitsPicked',
            'repairKitsPacked',
            'shipmentIdBeingPacked'
        ]),
    },

    watch: {
        repairKitsPicked() {
            this.checkShipmentComplete();
        }
    },

    methods: {
        ...mapActions([
            'clearShipmentScanned',
            'displayToast',
            'loadShipmentDetails',
            'setRepairKitAsPacked',
            'setShipmentScanned',
            'logToApi'
        ]),

        /** Check the barcode type */
        barcodeWasScanned(barcodeScanned) {
            if (barcodeScanned.type === 'shipment') {
                this.shipmentScanned(barcodeScanned);

            } else if (barcodeScanned.type === 'repairKit') {
                if (!this.shipmentIdBeingPacked) {
                    this.displayToast({text: 'Please scan a shipment first', type: 'error'});
                    return;
                }

                this.repairKitScanned(barcodeScanned);

            } else {
                this.displayToast({text: `You cannot scan this ${barcodeScanned.type} label here`, type: 'error'});
            }

        },

        /** Check if shipment is complete */
        checkShipmentComplete() {
            if (this.repairKitsPicked.length === 0 && this.shipmentIdBeingPacked) {
                this.logToApi({action: 'repair_kit_packing', event: 'finish', repairKitId: null, assetId: null, stockSerialNumberId: null, shipmentId: this.shipmentIdBeingPacked});

                this.clearShipmentScanned();
                this.displayToast({text: 'Shipment packing complete', type: 'success'});
            }
        },

        /** Repair kit scanned */
        repairKitScanned(barcodeScanned) {
            let kitBelongsToShipment = false;

            this.repairKits.forEach(repairKit => {
                if (barcodeScanned.id.toString() === repairKit.id.toString()) {
                    if (repairKit.state_constant === 'PACKED') {
                        this.displayToast({text: 'This kit has already been packed', type: 'error'});
                        kitBelongsToShipment = true;
                        return;
                    }

                    this.setRepairKitAsPacked(repairKit.id);
                    kitBelongsToShipment = true;

                    this.logToApi({action: 'repair_kit_packing', event: 'kit_packed', repairKitId: repairKit.id, assetId: null, stockSerialNumberId: null, shipmentId: this.shipmentIdBeingPacked});

                    this.displayToast({text: "Scanned!", type: 'success', duration: 2000});
                }
            });

            if (!kitBelongsToShipment) {
                this.displayToast({text: 'This kit does not belong to this shipment', type: 'error'});
            }
        },

        /** Shipment scanned */
        shipmentScanned(barcodeScanned) {
            this.clearShipmentScanned();
            this.loadShipmentDetails({
                barcodeScanned:barcodeScanned
            }).then(() => {
                // if (this.repairKitsPacked.length === 0) {
                //     this.displayToast({text: 'This shipment has already been packed', type: 'error'});
                //     return;
                // }
                this.logToApi({action: 'repair_kit_packing', event: 'start', repairKitId: null, assetId: null, stockSerialNumberId: null, shipmentId: barcodeScanned.id});
                this.setShipmentScanned(barcodeScanned.id);
            });
        }
    }
}
</script>

<style scoped>

</style>